.flex__align_center {
  display: flex;
  align-items: center;
}

.like__number {
  color: rgba(255, 255, 255, 0.66);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-left: 8px;
}

.creator__txt {
  color: #fff;
  display: inline-block;
}

.verified__img {
  height: 16px;
  margin-left: 8px;
  display: inline-block;
}

.flex {
  display: flex;
}

.map__img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.mt__10 {
  margin-top: 10px;
}

.w__100 {
  width: 100%;
}

.purchase__contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 28px;
}

.recaptcha {
  margin-top: 15px;
  margin-bottom: 15px;
}

.assets__container {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 36px;
}

.asset__item {
  overflow: hidden;
  width: 100%;
  max-width: 680px;
  position: relative;
}

.asset__item img {
  width: 680px;
  aspect-ratio: 1/1;
}

.asset__item video {
  width: 680px;
  aspect-ratio: 1/1;
}

.leftBtn {
  position: absolute;
  top: calc(50% - 17px - 54px);
  left: 10px;
  width: 34px;
  height: 34px;
  background: rgba(20, 20, 47, 1);
  border-radius: 44px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: none;
  justify-content: center;
  align-items: center;
}

.rightBtn {
  position: absolute;
  top: calc(50% - 17px - 54px);
  right: 10px;
  width: 34px;
  height: 34px;
  background: rgba(20, 20, 47, 1);
  border-radius: 44px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: none;
  justify-content: center;
  align-items: center;
}

.benefitImg {
  position: absolute;
  top: 12px;
  left: 12px;
  background: rgba(20, 20, 47, 0.8);
  border-radius: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.benefitText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #fff;
  margin: 0 0 0 6px;
}

.benefitIcon {
  width: 16px !important;
  height: 16px;
}

.smallIcon {
  width: 6px !important;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 6px;
}

@media (max-width: 770px) {
  .asset__item img {
    width: 100%;
    aspect-ratio: 1/1;
  }

  .asset__item video {
    width: 100%;
    aspect-ratio: 1/1;
  }
}

.event__image_contain {
  display: flex;
  width: 100%;
  aspect-ratio: 1/1;
}

.event__image_item {
  /* border: 1px solid rgba(255, 255, 255, 0.33); */
  border-radius: 12px;
  display: block;
}

.previewImages {
  margin-top: 8px;
  display: flex;
  gap: 8px;
  overflow: auto;
}

.pageNumber {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(20, 20, 47, 0.8);
  width: 53px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageText {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.smallPreivew {
  width: 100px !important;
  height: 100px;
  border-radius: 4px;
  cursor: pointer;
}

.asset__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 600px;
  flex: 1;
}

.asset__info_title {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 20px;
}

.asset__name {
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin: 0;
}

.asset__author {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 13px 0 16px 0;
}

.asset__author a {
  font-weight: 500;
  color: white;
  font-size: 16px;
}

.asset__author a:hover {
  color: #6164ff;
}

.asset__author_items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.text__small_title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 5px 0;
}

.collection__contain {
  display: flex;
  color: white;
  gap: 10px;
}

.asset__collection_img {
  height: 29px;
  width: 29px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
}

.text__small_val {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #fff;
  margin: 0px;
}

.asset__collection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.addons__detail_contain {
  width: 100%;
  margin: 17px 0;
  border-radius: 4px;
}

.addons__block_container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  padding: 14px;
  border-radius: 8px;
  background: linear-gradient(92.01deg, rgba(106, 77, 253, 0.2) 0%, rgba(106, 77, 253, 0.05) 104.91%);
}

.addons__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.addon_icon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 8px;
}

.addons__detail_text {
  margin-bottom: 0 !important;
  margin-left: 10px;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}

.addons__view_btn {
  box-sizing: border-box;
  width: 121.3px;
  height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 6px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.addons__view_btn:hover {
  border-color: white;
}

.map__btn {
  margin-top: 10px;
  width: 80.66px;
  height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.map__btn:hover {
  border-color: white;
}

.asset__timing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
}

.asset__desc {
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.66);
  font-weight: 400;
  margin-bottom: 0;
}

.assets__amount {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}

.assets__amount p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 35px;
  margin: 0px;
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #fff;
}

.assets__amount p:hover {
  cursor: pointer;
}

.assets__amount_number {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  width: 40px !important;
}

.generate__links {
  padding: 15px 0;
  color: white;
  padding: 10px;
}

.btn__sale_stop {
  height: 44px;
  border-radius: 4px;
  background-color: #6a4dfd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin: 10px 0;
  padding: 0 15px;
}

.btn__sale_stop:hover {
  margin: 8px 0 12px 0;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.see__more_txt {
  color: rgba(255, 255, 255, 0.66);
  font-size: 16px;
  cursor: pointer;
  margin: 0;
}

.like__share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.like__btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.like__btn img {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  background: none;
}

.like__btn p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.66);
}

.like__btn:hover p {
  color: white;
}

.like__btn:hover svg path {
  stroke-opacity: 1;
}

.share__container {
  background: #051006;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  width: 216px;
  position: absolute;
  top: 35px;
  right: 0px;
  padding: 24px;
}

.share__btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #6A4DFD;
  border-radius: 16px;
  padding: 3px 12px;
}

.share__btn img {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  background: none;
}

.share__btn p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.66);
  color: white;
}

.share__btn svg path {
  stroke-opacity: 1;
}

@media (max-width: 1100px) {
  .assets__container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .asset__info_title {
    margin-top: 20px;
  }
}

@media (max-width: 650px) {
  .assets__container {
    gap: 0;
  }
}

@media (max-width: 576px) {
  .asset__info {
    margin-left: 0px;
  }

  .asset__name {
    font-size: 24px;
    line-height: 28px;
  }

  .asset__item {
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 20px;
  }

  .asset__author {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}

@media (max-width: 450px) {
  .addons__view_btn {
    margin-top: 5px;
    width: 100%;
  }
}

@media (max-width: 360px) {
  .addon_icon {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    margin-right: 8px;
  }
}
