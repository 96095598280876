.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  max-width: 1224px;
  padding: 0;
  z-index: 1000;
}
.header__actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  width: 100%;
  position: relative;
  padding: 0;
}
@media (max-width: 1250px) {
  .header__content {
    padding: 0 10px;
  }
}
@media (max-width: 290px) {
  .header__content {
    flex-direction: column;
    gap: 10px;
  }
}
.header__logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.header__logo img {
  margin-top: 30px;
  height: 90px;
}
.header__subcontainer {
  display: flex;
  align-items: center;
}
.header__btn {
  position: absolute;
  width: 22px;
  height: 22px;
  display: block;
  right: 15px;
  top: 24px;
}
.header__btn span {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  right: 0;
  width: 22px;
  height: 2px;
  background-color: #bdbdbd;
  border-radius: 2px;
  transition: 0.5s ease;
  transition-property: width, background-color;
}
.header__btn span:first-child {
  top: 0;
}
.header__btn span:nth-child(2) {
  top: 10px;
  width: 16px;
}
.header__btn span:last-child {
  top: 20px;
  width: 10px;
}
.header__btn:hover span {
  background-color: #6164ff;
}
.header__btn--active span {
  background-color: #6164ff;
}
.header__btn--active span:nth-child(2) {
  width: 22px;
}
.header__btn--active span:last-child {
  width: 22px;
}

.header__menu--active {
  transform: translate3d(0, 0, 0);
}
.header__nav {
  display: flex;
  align-items: center;
}
.header__nav_item {
  position: relative;
  margin-right: 20px;
}
@media (max-width: 500px) {
  .header__nav_item {
    position: relative;
    margin-right: 10px;
  }
}
@media (max-width: 320px) {
  .header__nav_item {
    margin-right: 10px;
  }
}
.header__nav_item:last-child {
  margin-bottom: 0;
}
.header__nav_link {
  display: flex;
  font-size: 14px;
  color: #bdbdbd;
  align-items: center;
}
.header__nav_link p {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-left: 10px;
  letter-spacing: 0.15em;
  margin-top: 0;
}
.header__nav_link svg {
  fill: #bdbdbd;
  width: 14px;
  height: auto;
  transition: fill 0.5s ease;
  margin-left: 1px;
  margin-top: 2px;
}
.header__nav_link--menu svg {
  width: 20px;
  margin-top: 0;
  margin-left: 0;
}
.header__nav_link--active {
  color: #6164ff;
  cursor: default;
  font-weight: 500;
}
.header__nav_link--active:hover {
  color: #6164ff !important;
}
.header__nav_link:hover,
.header__nav_link[aria-expanded="true"] {
  color: #fff;
}
.header__nav_link:hover svg,
.header__nav_link[aria-expanded="true"] svg {
  fill: #6164ff;
}
.header__nav-menu {
  display: block;
  position: absolute !important;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  top: 0;
  background-color: #14142f;
  border-radius: 16px;
  padding: 0 20px;
  width: 200px;
  transition: opacity 0.5s ease;
  transform: translate3d(0px, 22px, 0px);
  margin-top: 10px;
  height: auto;
  border: 1px solid #887bff;
}
.header__nav-menu .header__nav-menu {
  margin-top: 5px;
  margin-left: 20px;
}
.header__nav-menu li {
  position: relative;
  margin-bottom: 15px;
}
.header__nav-menu li:first-child {
  padding-top: 20px;
}
.header__nav-menu li:last-child {
  margin-bottom: 0;
  padding-bottom: 20px;
}
.header__nav-menu a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #bdbdbd;
  font-size: 14px;
  font-weight: 400;
  transition: color 0.5s ease;
  position: relative;
}
.header__nav-menu a svg {
  fill: #bdbdbd;
  width: 14px;
  height: auto;
  transition: 0.5s ease;
  margin-left: 1px;
  margin-top: 2px;
}
.header__nav-menu a:hover,
.header__nav-menu a[aria-expanded="true"] {
  color: #fff;
}
.header__nav-menu a:hover svg,
.header__nav-menu a[aria-expanded="true"] svg {
  fill: #6164ff;
}
.header__nav-menu.show {
  z-index: 1000;
  pointer-events: auto;
  opacity: 1;
}
.header__profile_btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 0 0 6px;
  height: 54px;
}
.header__profile_btn img {
  display: none;
  background: rgba(255, 255, 255, 0.15);
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 9px;
}
.header__profile_btn div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.header__profile_btn p {
  margin-bottom: 0;
  line-height: 22px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.header__profile_btn span {
  font-size: 12px;
  color: #bdbdbd;
  line-height: 18px;
}
.header__profile_btn svg {
  fill: #bdbdbd;
  width: 16px;
  height: auto;
  transition: fill 0.5s ease;
  margin-left: 6px;
  margin-top: 2px;
}
.header__profile_btn:hover {
  border-color: #6164ff;
}
.header__profile_btn:hover svg,
.header__profile_btn[aria-expanded="true"] svg {
  fill: #6164ff;
}
@media (max-width: 576px) {
  .header {
    height: 80px;
  }
  .header__logo img {
    height: 70px;
  }
  .header__nav_item:last-child {
    margin-right: 0px;
  }
  .header__content {
    padding: 0 10px;
  }
}
@media (min-width: 576px) {
  .header__btn {
    right: 30px;
  }
  .header__profile_btn img {
    display: block;
  }
  .header__profile_btn--verified:after {
    display: block;
  }
}
@media (min-width: 1200px) {
  .header__logo {
    width: auto;
    margin-right: 50px;
  }
  .header__btn {
    display: none;
  }
  .header__actions {
    margin-right: 0;
  }
  .header__action--search {
    display: none;
  }
  .header__nav {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
  .header__nav-menu {
    transform: translate3d(0px, 46px, 0px);
  }
  .header__nav-menu .header__nav-menu {
    transform: translate3d(0px, 22px, 0px);
  }
}

.header__menu_mobile {
  overflow: auto;
}

@media (max-width: 768px) {
  .header__menu_mobile {
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: #14142f;
    color: #fff;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
    padding-top: 10px;
  }
  .header__menu_top {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 80px;
    justify-content: space-between;
  }
  .header__menu_top button {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 32px;
    height: 32px;
  }
  .header__menu_top img {
    height: 32px;
  }
  .header__menu_user {
    display: flex;
    align-items: center;
  }
  .header__menu_user__logo {
    height: 44px;
    width: 44px;
    margin-right: 10px;
    border-radius: 22px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.1);
  }
  .header__menu_user__logo img {
    width: 100%;
    height: 100%;
  }
  .header__menu_user__info {
    display: flex;
    align-items: center;
  }
  .header__menu_user__info img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  .header__menu_user__name {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.03em;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header__menu_user__type {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.15em;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.66);
  }
  .header__menu_items {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .header__menu_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 60px;
    border-bottom: 0.5px solid rgba(121, 126, 137, 0.5);
  }
  .header__menu_item__container {
    display: flex;
    width: 100%;
    align-items: center;
    border: none !important;
  }
  .header__menu_text {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .admin__menu_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    padding-left: 30px;
  }
  .admin__menu_item img {
    margin-right: 15px;
    width: 16px;
  }
  .admin__menu_container {
    display: flex;
    border-top: none;
  }
  .admin__menu_text {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.66);
  }
  .header__search_mobile {
    position: fixed;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.66);
    color: #fff;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .header__search_top {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    width: 100%;
    height: 80px;
    background-color: #14142f;
    justify-content: space-between;
  }
  .header__search_top button {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 32px;
    height: 32px;
  }
}
.header__username_contain {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.header__username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 700;
}
.header__verified {
  background: #2f80ed !important;
  width: 12px !important;
  height: 12px !important;
  margin-left: 4px;
  border: 1px solid white;
}
.header__admin_user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.15em;
}
.header__mobile_menu {
  display: none;
}
@media (max-width: 768px) {
  .header__mobile_menu {
    display: block;
  }
}
.bell__red {
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
}
.bell__img {
  cursor: pointer;
}
.border__top_item {
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 0;
}
.search__btn_container {
  background: #14142f;
}
.header__sign_out {
  padding: 30px 20px 50px 20px;
}
.header__new_article {
  margin-top: -10px;
  margin-bottom: 20px;
  width: 100%;
}
.sub__menu_img {
  margin-right: 40px;
}
.sub__menu_item {
  display: flex;
  align-items: center;
}
.btn_close {
  padding: 0 !important;
}
.search__visible {
  display: block;
}
@media (max-width: 980px) {
  .search__visible {
    display: none;
  }
}
.sign__menu {
  margin-bottom: 10px !important;
}
.create__account_txt:hover {
  color: rgba(255, 255, 255, 0.66) !important;
}
@media (max-width: 290px) {
  .header__logo img {
    height: 40px;
    margin-top: 10px;
  }
}
