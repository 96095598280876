.collection {
  display: flex;
  flex-direction: column;
  margin-top: 20px !important;
  height: 288px;
  border-radius: 12px;
  width: 288px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.33);
  margin-bottom: 10px;
}
.collection:hover {
  margin-top: 18px !important;
  margin-bottom: 12px;
  box-shadow: 0px 0px 8px #4dfdd4;
}
.collection__cover {
  width: 288px;
  height: 144px;
}
.collection__meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 144px;
}
.collection__name {
  margin-top: 48px;
  position: relative;
  text-align: center;
  font-size: 19px;
  line-height: 24px;
  color: #fff;
  font-weight: 700;
  max-width: 250px;
}
.collection__name a {
  color: #fff;
  letter-spacing: 0.02em;
}
.collection__name a:hover {
  color: #6164ff;
}
.collection__number {
  text-align: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.66);
  font-weight: 400;
  letter-spacing: 0.02em;
}
.collection__avatar {
  position: absolute;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -44px;
  margin-bottom: 10px;
  background-color: pink;
}
.collection__avatar img {
  width: 88px;
  height: 88px;
}
@media (max-width: 576px) {
  .collection__cover {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .collection {
    margin: auto;
  }
}
@media (max-width: 320px) {
  .collection {
    width: 100%;
  }
}
