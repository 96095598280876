.sit_contain {
  position: relative;
}

.pieceMap {
  width: 100%;
  opacity: 1;
  cursor: pointer;
}

.pieceMap:hover {
  opacity: 0.6;
  /* border: 1px solid white; */
}

.stage {
  position: absolute;
  top: 3%;
  left: 35%;
  width: 30%;
}

.stage_img {
  width: 100%;
}

.zoom_out {
  position: absolute;
  bottom: 60px;
  left: 30px;
  z-index: 1000;
}

.zoom_in {
  position: absolute;
  bottom: 24px;
  left: 30px;
  z-index: 1000;
}

.orchestra_center {
  position: absolute;
  top: 17%;
  left: 43%;
  width: 14%;
}

.orchestra_left {
  position: absolute;
  top: 17%;
  left: 25%;
  width: 14%;
}

.orchestra_right {
  position: absolute;
  top: 17%;
  right: 25%;
  width: 14%;
}

.gold_center {
  position: absolute;
  top: 36%;
  left: 43%;
  width: 14%;
}

.gold_left {
  position: absolute;
  top: 36%;
  left: 25%;
  width: 14%;
}

.gold_right {
  position: absolute;
  top: 36%;
  right: 25%;
  width: 14%;
}

.left_top {
  position: absolute;
  top: 17%;
  left: 12%;
  width: 9%;
}

.left_middle {
  position: absolute;
  top: 30%;
  left: 12%;
  width: 8%;
}

.left_bottom {
  position: absolute;
  top: 43%;
  left: 12%;
  width: 7.5%;
}

.right_top {
  position: absolute;
  top: 17%;
  right: 12%;
  width: 9%;
}

.right_middle {
  position: absolute;
  top: 30%;
  right: 12%;
  width: 8%;
}

.right_bottom {
  position: absolute;
  top: 43%;
  right: 12%;
  width: 7.5%;
}

.rear_1 {
  position: absolute;
  top: 52%;
  right: 52%;
  width: 10%;
}

.rear_3 {
  position: absolute;
  top: 52%;
  left: 30%;
  width: 5%;
}

.rear_2 {
  position: absolute;
  top: 52%;
  left: 52%;
  width: 10%;
}

.rear_4 {
  position: absolute;
  top: 52%;
  right: 30%;
  width: 5%;
}