.footer {
  width: 100%;
  max-width: 1224px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.footer__logo {
  margin-top: 60px;
}
.footer__logo img {
  width: auto;
  height: 90px;
  display: block;
}
.footer__item {
  display: flex;
}

.footer__social {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer__social_items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
}
.footer__social a {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
}
.footer__social svg {
  transition: fill 0.5s ease;
}
.footer__social a:last-child {
  margin-right: 0;
}
.footer__fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.footer__chain {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footer__chain p {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 10px;
  letter-spacing: 0.02em;
}
.footer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
@media (max-width: 450px) {
  .footer__content {
    padding-bottom: 40px;
  }
}
.footer__end {
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  margin-top: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer__copyright {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.66);
  margin-top: 20px;
  font-weight: 400;
  letter-spacing: 0.02em;
}
.footer__copyright a {
  color: #bdbdbd;
}
.footer__copyright a:hover {
  color: #6164ff;
}
.footer__developed_text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.66);
  margin-top: 20px;
  font-weight: 400;
  letter-spacing: 0.02em;
}
@media (max-width: 450px) {
  .footer__copyright {
    text-align: center;
  }
  .footer__developed_text {
    max-width: 174px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
  }
}
.footer__title {
  margin-top: 30px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.footer__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 180px;
}
.footer__nav a {
  font-size: 14px;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.66);
  font-weight: 400;
}
.footer__nav a:last-child {
  margin-bottom: 0;
}
.footer__nav a:hover {
  color: #fff;
}
@media (max-width: 576px) {
  .footer__nav {
    margin-right: 0;
  }
}
@media (max-width: 680px) {
  .footer__fields {
    display: block;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .footer {
    padding: 20px;
  }
  .footer__logo img {
    height: 70px;
  }
  .footer__item {
    display: block;
  }
  .footer__end {
    display: block;
    justify-content: space-between;
    height: 120px;
  }
}
@media (max-width: 1250px) {
  .footer {
    padding: 0 10px;
  }
}

@media (max-width: 290px) {
  .footer__logo img {
    height: 40px;
  }
}
